.dentalservicesparent{
    width: 100%;
    height: auto;
    display: flex;
}

.dentalservicesimgdiv{
    width: 30%;
    height: 600px;
}

.dentalservicesimg{
    width: 100%;
    height: 100%;
}

.dentalservices{
    width: 70%;
    background-color: lightcoral;
    font-family: Arial, Helvetica, sans-serif;
}

.dentalservicesdiv1{
    width: 30%;
    margin: 0px auto;
}

.aestheticservicesparent{
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 10%;
}

.aestheticservices{
    width: 65%;
    background-color: lightcoral;
    font-family: Arial, Helvetica, sans-serif;
}

.aestheticservicesimgdiv{
    width: 40%;
    height: 635px;
}

.aestheticservicesimg{
    width: 100%;
    height: 100%;
}

.aestheticservicesdiv1{
    width: 30%;
    margin: 0px auto;
}

@media only screen and (max-width: 414px){
    .dentalservicesparent{
        display: flex;
        flex-direction: column;
    }

    .dentalservicesimgdiv{
        width: 100%;
    }

    .dentalservicesimg{
        width: 100%;
    }

    .dentalservices{
        width: 100%;
    }

    .dentalservicesdiv1{
        height: auto;
        width: 50%;
    }

    .aestheticservicesparent{
        display: flex;
        flex-direction: column;
    }

    .aestheticservices{
        width: 100%;
    }

    .aestheticservicesdiv1{
        width: 50%;
    }

    .aestheticservicesimgdiv{
        width: 100%;
    }

    .aestheticservicesimg{
        width: 100%;
    }
}

@media (max-width: 575.98px) and (min-width: 414px){
    .dentalservicesparent{
        display: flex;
        flex-direction: column;
    }

    .dentalservicesimgdiv{
        width: 100%;
    }

    .dentalservicesimg{
        width: 100%;
    }

    .dentalservices{
        width: 100%;
    }

    .dentalservicesdiv1{
        height: auto;
        width: 50%;
    }

    .aestheticservicesparent{
        display: flex;
        flex-direction: column;
    }

    .aestheticservices{
        width: 100%;
    }

    .aestheticservicesdiv1{
        width: 50%;
    }

    .aestheticservicesimgdiv{
        width: 100%;
    }

    .aestheticservicesimg{
        width: 100%;
    }
}

@media (min-width: 576px) and (max-width: 768px){
    .dentalservicesparent{
        display: flex;
        flex-direction: column;
    }

    .dentalservicesimgdiv{
        width: 100%;
    }

    .dentalservicesimg{
        width: 100%;
    }

    .dentalservices{
        width: 100%;
    }

    .dentalservicesdiv1{
        height: auto;
        width: 50%;
    }

    .aestheticservicesparent{
        display: flex;
        flex-direction: column;
    }

    .aestheticservices{
        width: 100%;
    }

    .aestheticservicesdiv1{
        width: 50%;
    }

    .aestheticservicesimgdiv{
        width: 100%;
    }

    .aestheticservicesimg{
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 992px){
    .dentalservicesparent{
        display: flex;
        flex-direction: column;
    }

    .dentalservicesimgdiv{
        width: 100%;
    }

    .dentalservicesimg{
        width: 100%;
    }

    .dentalservices{
        width: 100%;
    }

    .dentalservicesdiv1{
        height: auto;
        width: 50%;
    }

    .aestheticservicesparent{
        display: flex;
        flex-direction: column;
    }

    .aestheticservices{
        width: 100%;
    }

    .aestheticservicesdiv1{
        width: 50%;
    }

    .aestheticservicesimgdiv{
        width: 100%;
    }

    .aestheticservicesimg{
        width: 100%;
    }
}