.nav-link.custom-link {
    color: lightcoral !important;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .nav-link.custom-link:hover {
    color: pink !important;
  }
  
  .custom-navbar {
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional for better visibility */
}
