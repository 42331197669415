.bluecover {
    width: 100%;
    height: auto;
    margin-top: 1%;
    background-color: lightcoral;
    opacity: 0.8;
    z-index: 1; /* Ensure it stays behind the navbar */
    position: relative; /* Required when using z-index */
    padding: 6% 0 8% 0;
}


.bluecoverparent1{
    display: flex;
}

.bluecovertext{
    width: 100%;
    height: auto;
    margin-top: 60px;
    margin-left: 15px;
    font-family: Arial, Helvetica, sans-serif;
}

.whitelogodiv{
    width: 100%;
    height: auto;
}

.whitelogo{
    width: 100%;
    margin-top: 5%;
}

.dentalexcellence{
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 10%;
}

.excellencephoto{
    width: 100%;
}

.dentalimg{
    border-radius: 2%;
    margin-left: 15%;
}

.excellencetext{
    width: 100%;
}

.cutc{
    width: 100%;
    display: flex;
    background-color: lightcoral;
    margin-top: 10%;
    height: auto;
    border-radius: 3%;
    padding: 3% 0 5% 0;
}

.cutctext{
    width: 100%;
    margin-top: 2%;
    margin-left: 8%;
}

.cutcbutton{
    width: 100%;
}

.proceduresdiv{
    width: 100%;
}

.procedures{
    width: 100%;
    display: flex;
}

.di{
    width: 100%;
    background-color: lightcoral;
    border-radius: 3%;
    height: auto;
    margin-left: 2%;
}

.diimg{
    margin-left: 40%;
    width: 15%;
    margin-top: 2%;
}

.cd{
    width: 100%;
    background-color: lightcoral;
    border-radius: 3%;
    height: auto;
    margin-left: 2%;
}

.cdimg{
    margin-left: 40%;
    width: 15%;
    margin-top: 2%;
}

.rc{
    width: 100%;
    background-color: lightcoral;
    border-radius: 3%;
    height: auto;
    margin-left: 2%;
    margin-right: 2%;
}

.rcimg{
    margin-left: 40%;
    width: 15%;
    margin-top: 2%;
}

.proceduresdiv2{
    width: 100%;
    display: flex;
    margin-top: 3%;
}

.procedures2{
    width: 100%;
    display: flex;
}

.tw{
    width: 100%;
    background-color: lightcoral;
    border-radius: 3%;
    height: auto;
    margin-left: 2%;
}

.dx{
    width: 100%;
    background-color: lightcoral;
    border-radius: 3%;
    height: auto;
    margin-left: 2%;
}

.bi{
    width: 100%;
    background-color: lightcoral;
    border-radius: 3%;
    height: auto;
    margin-left: 2%;
    margin-right: 2%;
}

.twimg,
.dximg,
.biimg{
    margin-left: 40%;
    width: 15%;
    margin-top: 2%;
}

.happystoriesdiv{
    width: 100%;
    display: flex;
}

.aa{
    width: 100%;
    background-color: lightcoral;
    border-radius: 3%;
    height: auto;
    margin-left: 2%;
}

.sa{
    width: 100%;
    background-color: lightcoral;
    border-radius: 3%;
    height: auto;
    margin-left: 2%;
}

.hbh{
    width: 100%;
    background-color: lightcoral;
    border-radius: 3%;
    height: auto;
    margin-left: 2%;
    margin-right: 2%;
}

.aaimg,
.saimg,
.hbhimg{
    margin-left: 40%;
    width: 15%;
    margin-top: 2%;
}

.google{
    margin-left: 37%;
    font-size: 200%;
    color: red;
}

.locationdiv{
    width: 100%;
    margin-bottom: 5%;
}
.locimg{
    width: 100%;
}


@media only screen and (max-width: 414px){
    html, body {
        margin: 0;
        padding: 0;
        overflow-x: hidden; /* Right side ke extra space ko remove karne ke liye */
        overflow-y: scroll; /* Downward scroll karne ke liye */
    }
    .bluecoverparent1{
        display: flex;
        flex-direction: column;
    }
    .dentalexcellence{
        display: flex;
        flex-direction: column;
    }
    .dentalimg{
        width: 100%;
        margin-left: 0%;
        border-radius: 0%;
    }
    .excellencetext h1, h5{
        margin-left: 2%;
    }
    .cutc{
        display: flex;
        flex-direction: column;
    }
    .cutctext{
        margin-left: 0%;
    }
    .procedures{
        display: flex;
        flex-direction: column;
    }
    .di{
        margin-left: 0%;
    }
    .cd{
        margin-left: 0%;
        margin-top: 2%;
    }
    .rc{
        margin-left: 0%;
        margin-top: 2%;
    }
    .procedures2{
        display: flex;
        flex-direction: column;
    }
    .tw{
        margin-left: 0%;
    }
    .dx{
        margin-left: 0%;
        margin-top: 2%; 
    }
    .bi{
        margin-left: 0%;
        margin-top: 2%;
    }
    .happystoriesdiv{
        display: flex;
        flex-direction: column;
    }
    .aa{
        margin-left: 0%;
    }
    .sa{
        margin-left: 0%;
        margin-top: 2%; 
    }
    .hbh{
        margin-left: 0%;
        margin-top: 2%;
    }
    .google{
        margin-left: 30%;
    }
}

@media screen and (max-width: 480px){
    html, body {
        margin: 0;
        padding: 0;
        overflow-x: hidden; /* Right side ke extra space ko remove karne ke liye */
        overflow-y: scroll; /* Downward scroll karne ke liye */
    }
    .bluecoverparent1{
        display: flex;
        flex-direction: column;
    }
    .dentalexcellence{
        display: flex;
        flex-direction: column;
    }
    .dentalimg{
        width: 100%;
        margin-left: 0%;
        border-radius: 0%;
    }
    .excellencetext h1, h5{
        margin-left: 2%;
    }
    .cutc{
        display: flex;
        flex-direction: column;
    }
    .cutctext{
        margin-left: 0%;
    }
    .procedures{
        display: flex;
        flex-direction: column;
    }
    .di{
        margin-left: 0%;
    }
    .cd{
        margin-left: 0%;
        margin-top: 2%;
    }
    .rc{
        margin-left: 0%;
        margin-top: 2%;
    }
    .procedures2{
        display: flex;
        flex-direction: column;
    }
    .tw{
        margin-left: 0%;
    }
    .dx{
        margin-left: 0%;
        margin-top: 2%; 
    }
    .bi{
        margin-left: 0%;
        margin-top: 2%;
    }
    .happystoriesdiv{
        display: flex;
        flex-direction: column;
    }
    .aa{
        margin-left: 0%;
    }
    .sa{
        margin-left: 0%;
        margin-top: 2%; 
    }
    .hbh{
        margin-left: 0%;
        margin-top: 2%;
    }
    .google{
        margin-left: 30%;
    }
}

@media (max-width: 575.98px) and (min-width: 414px){
    html, body {
        margin: 0;
        padding: 0;
        overflow-x: hidden; /* Right side ke extra space ko remove karne ke liye */
        overflow-y: scroll; /* Downward scroll karne ke liye */
    }
    .bluecoverparent1{
        display: flex;
        flex-direction: column;
    }
    .dentalexcellence{
        display: flex;
        flex-direction: column;
    }
    .dentalimg{
        width: 100%;
        margin-left: 0%;
        border-radius: 0%;
    }
    .excellencetext h1, h5{
        margin-left: 2%;
    }
    .cutc{
        display: flex;
        flex-direction: column;
    }
    .cutctext{
        margin-left: 0%;
    }
    .procedures{
        display: flex;
        flex-direction: column;
    }
    .di{
        margin-left: 0%;
    }
    .cd{
        margin-left: 0%;
        margin-top: 2%;
    }
    .rc{
        margin-left: 0%;
        margin-top: 2%;
    }
    .procedures2{
        display: flex;
        flex-direction: column;
    }
    .tw{
        margin-left: 0%;
    }
    .dx{
        margin-left: 0%;
        margin-top: 2%; 
    }
    .bi{
        margin-left: 0%;
        margin-top: 2%;
    }
    .happystoriesdiv{
        display: flex;
        flex-direction: column;
    }
    .aa{
        margin-left: 0%;
    }
    .sa{
        margin-left: 0%;
        margin-top: 2%; 
    }
    .hbh{
        margin-left: 0%;
        margin-top: 2%;
    }
    .google{
        margin-left: 30%;
    }
}

@media (min-width: 576px) and (max-width: 768px){
    html, body {
        margin: 0;
        padding: 0;
        overflow-x: hidden; /* Right side ke extra space ko remove karne ke liye */
        overflow-y: scroll; /* Downward scroll karne ke liye */
    }
    .bluecoverparent1{
        display: flex;
        flex-direction: column;
    }
    .dentalexcellence{
        display: flex;
        flex-direction: column;
    }
    .dentalimg{
        width: 100%;
        margin-left: 0%;
        border-radius: 0%;
    }
    .excellencetext h1, h5{
        margin-left: 2%;
    }
    .cutc{
        display: flex;
        flex-direction: column;
    }
    .cutctext{
        margin-left: 0%;
    }
    .procedures{
        display: flex;
        flex-direction: column;
    }
    .di{
        margin-left: 0%;
    }
    .cd{
        margin-left: 0%;
        margin-top: 2%;
    }
    .rc{
        margin-left: 0%;
        margin-top: 2%;
    }
    .procedures2{
        display: flex;
        flex-direction: column;
    }
    .tw{
        margin-left: 0%;
    }
    .dx{
        margin-left: 0%;
        margin-top: 2%; 
    }
    .bi{
        margin-left: 0%;
        margin-top: 2%;
    }
    .happystoriesdiv{
        display: flex;
        flex-direction: column;
    }
    .aa{
        margin-left: 0%;
    }
    .sa{
        margin-left: 0%;
        margin-top: 2%; 
    }
    .hbh{
        margin-left: 0%;
        margin-top: 2%;
    }
    .google{
        margin-left: 30%;
    }
}