.aboutimgdiv{
    width: 100%;
}

.aboutimg{
    width: 100%;
}

.introductiontext{
    width: 98%;
}

.meetthestaffdiv{
    width: 100%;
    height: auto;
    font-family: Arial, Helvetica, sans-serif;
}