.contactusdiv{
    width: 100%;
    height: auto;
}

.phoneiconcontact{
    font-size: 2vw;
    margin-left: 5%;
    color: lightcoral;
} 

.emailiconcontact{
    font-size: 2vw;
    margin-left: 5%;
    color: lightcoral;
}

.locationiconcontact{
    font-size: 2vw;
    margin-left: 5%;
    color: lightcoral;
}